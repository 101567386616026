import { INIT, SEARCH } from "./action-types";
import {
  SET_CANCEL_TOKEN,
  SET_ENTITIES,
  SET_IS_DEFAULT_LOADED,
  SET_IS_LOADING
} from "./mutation-types";
import axios from "axios";

export default apiModule => ({
  async [INIT]({ state, commit, dispatch }, filters = {}) {
    await dispatch(SEARCH, Object.assign(state.filters, filters));
    if (!state.isDefaultLoaded) {
      commit(SET_IS_DEFAULT_LOADED, true);
    }
  },
  async [SEARCH]({ state, commit }, filters) {
    // Flag as loading.
    commit(SET_IS_LOADING, true);

    // Convert the filter object to a json string, so we can uniquely identify the call.
    const call = JSON.stringify(filters);

    // Check if a cancel token already exists for the call in question, and if so, cancel
    // said previous call to prevent race conditions
    if (state.cancelTokens[call]) {
      state.cancelTokens[call].cancel("Only one request allowed at a time.");
    }
    // Create a fresh cancelToken
    const cancelToken = axios.CancelToken.source();
    // Commit the new fresh cancelToken for the current call.
    commit(SET_CANCEL_TOKEN, { call, cancelToken });

    // Attempt to fetch the requested data, providing filters and a cancelToken to the api.
    const { entities, result } = await apiModule.fetchAll({
      filters,
      cancelToken: cancelToken.token
    });
    // If successful, set the entities & unflag as loading.
    commit(SET_ENTITIES, { entities, ids: result });
    commit(SET_IS_LOADING, false);
    return result;
  }
});
