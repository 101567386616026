import initialSelectState from "@/store/templates/b-select/state";
import selectGetters from "@/store/templates/b-select/getters";
import selectActions from "@/store/templates/b-select/actions";
import selectMutations from "@/store/templates/b-select/mutations";

export default (schema, apiModule, { state = {} } = {}) => ({
  namespaced: true,
  state: { ...initialSelectState(state) },
  getters: { ...selectGetters(schema) },
  actions: { ...selectActions(apiModule) },
  mutations: { ...selectMutations }
});
