import Vue from "vue";
import {
  SET_CANCEL_TOKEN,
  SET_ENTITIES,
  SET_IS_DEFAULT_LOADED,
  SET_IS_LOADING
} from "./mutation-types";

export default {
  [SET_ENTITIES](state, { entities, ids }) {
    Vue.set(state, "ids", ids);
    entities = Object.freeze(entities);
    Vue.set(state, "entities", entities);
  },
  [SET_IS_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },
  [SET_IS_DEFAULT_LOADED](state, isDefaultLoaded) {
    state.isDefaultLoaded = isDefaultLoaded;
  },
  [SET_CANCEL_TOKEN](state, { call, cancelToken }) {
    state.cancelTokens[call] = cancelToken;
  }
};
