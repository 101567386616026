import { createSelectEntityPlaceholders } from "@/store/helpers";

export default ({ entities = [], filters = {} }) => ({
  ...createSelectEntityPlaceholders(entities),
  ids: [],
  filters,
  cancelTokens: {},
  isLoading: false,
  isDefaultLoaded: false
});
