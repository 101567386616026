// select template
export const SET_ENTITIES = "setEntities";
export const SET_IS_LOADING = "setIsLoading";
export const SET_CANCEL_TOKEN = "setCancelToken";
export const REMOVE_CANCEL_TOKEN = "removeCancelToken";
export const SET_IS_DEFAULT_LOADED = "setIsDefaultLoaded";
export const SET_IS_INITIALIZED = "setIsInitialized";
export const SET_SEARCH_FILTER = "setSearchFilter";
export const SET_ASYNC_CACHE = "setAsyncCache";
export const SET_FILTER = "setFilter";
